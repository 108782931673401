import React from "react"
import styles from "./index.module.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Menu from "./menu"
import Dropdown from "./dropdown"
import AboutDropdown from "./aboutDropdown"
import Cookies from "js-cookie"

const getCountry = () => {
  let country = Cookies.get("country")

  return country
}

const isBase = () => {
  let isBase = parseInt(Cookies.get("isBase"))

  if (isBase === 1 || isBase === 0) {
    return !!isBase
  } else {
    return false
  }
}

export const getUrl = url => {
  if (getCountry() === null || getCountry() === undefined) {
    return `/${url}`
  }

  return `${!isBase() ? `/${getCountry()}` : ""}/${url}`
}

const Header = ({ showNav, currency }) => {
  const data = useStaticQuery(graphql`
    query img {
      logo: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      text: file(relativePath: { eq: "Referment Text Logo White 2021.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [reveal, setReveal] = React.useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset
    if (currentScrollPos > 40) {
      if (!reveal) {
        setReveal(true)
      }
    } else {
      setReveal(false)
    }
  }

  const memo = React.useCallback(handleScroll, handleScroll)

  React.useEffect(() => {
    setReveal(showNav)
  }, [showNav])

  React.useEffect(() => {
    window.addEventListener("scroll", memo)
    return function cleanup() {
      window.removeEventListener("scroll", memo)
    }
  }, [memo])

  return (
    <nav className={`${reveal ? "bg-primary p-3" : "p-6"} ${styles.navbar}`}>
      <div className="container mx-auto flex justify-between ">
        <Link className="flex items-center" to={getUrl("")}>
          <Img
            className={styles.logo}
            fluid={data.logo?.childImageSharp?.fluid}
          />
          <Img
            className={styles.logotext}
            fluid={data.text?.childImageSharp?.fluid}
          />
        </Link>
        <Menu />
        <div className="hidden lg:flex items-center ">
          <Dropdown />
          <AboutDropdown />
          <Link className={styles.link} to="/blog">
            Blog
          </Link>
          <Link className={styles.link} to={getUrl("faq")}>
            FAQ
          </Link>
          <Link className={styles.link} to={getUrl("clients#contact")}>
            Contact Us
          </Link>
          <Link
            className={"btn-outline border-white ml-8 mr-8"}
            to={getUrl("clients")}
          >
            Hire with Referment
          </Link>
          <a
            href={"https://app.referment.com"}
            rel="noopener noreferrer"
            target="_blank"
            className={"btn"}
          >
            View Our Open Jobs
          </a>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  showNav: PropTypes.bool,
}

export default Header
