import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Instagram = ({ url }) => {
  const data = useStaticQuery(graphql`
    query instaicon {
      file(relativePath: { eq: "socialIcons/instagram.png" }) {
        childImageSharp {
          fixed(width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={url}
      className={"mx-2 expand"}
    >
      <Img fixed={data.file.childImageSharp.fixed} />
    </a>
  )
}

Instagram.propTypes = {
  url: PropTypes.string,
}

export default Instagram
