import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const Email = ({ email }) => {
  const data = useStaticQuery(graphql`
    query emailicons {
      file(relativePath: { eq: "socialIcons/email.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a href={`mailto:${email}`} className={"expand"}>
      <Img fixed={data?.file?.childImageSharp?.fixed} />
    </a>
  )
}

Email.propTypes = {
  email: PropTypes.string,
}

export default Email
