import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Email from "../socialIcons/email"
import Linkedin from "../socialIcons/linkedin"
import Twitter from "../socialIcons/twitter"
import Instagram from "../socialIcons/instagram"
import Phone from "../socialIcons/phone"
import Img from "gatsby-image"
import "./index.css"
import Fade from "react-reveal/Fade"
import { getUrl } from "../header"
import Cookies from "js-cookie"

export default () => {
  const data = useStaticQuery(graphql`
    query footerLogo {
      logo: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      brentford: file(relativePath: { eq: "Brentford Referment White.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // return (
  //   <>
  //     <div className={"pb-16 mt-16 container snap-child mx-auto flex flex-col lg:flex-row"}>
  //       <div className={"flex flex-col flex-1"}>
  //         <div className={"w-32 d-flex items-center justify-items-center text-center"}>
  //           <Img
  //             className="h-12 mx-auto w-12"
  //             fluid={data.file.childImageSharp?.fluid}
  //           />
  //           <div className={"text-secondary"}>©2020</div>
  //         </div>
  //       </div>
  //       <div className={"flex-1 my-auto text-center"}>
  //         About | Contact | Legal | For Clients | Referment Platform{" "}
  //       </div>
  //       <div className={"flex-1"}></div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <footer
        style={{ backgroundColor: "#111122" }}
        className={`footerStyle mb-0 pt-10 sm:mt-10`}
      >
        <Fade cascade>
          <div>
            <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-left">
              <div className="p-5 w-full md:w-1/2">
                <div className="text-xs text-gray-400 font-medium mb-6">
                  <div className="flex">
                    <a
                      href="https://referment.com/blog/post/the-official-fintech-recruitment-partners-for-brentford-fc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        className="w-64 h-auto"
                        fluid={data.brentford?.childImageSharp?.fluid}
                      />
                    </a>
                  </div>
                  <br />
                  <div className={"light mr-12"}>
                    This is Referment. We connect untapped talent with
                    career-defining roles at some of the world’s leading FinTech
                    companies. We do this by crowd-sourcing referrals from our
                    community, who refer the people they know to new jobs on our
                    platform and earn referral rewards when their referral
                    interviews.
                  </div>
                  <div class="light pt-2 hidden lg:block">
                    <div class="text-xs uppercase medium text-gray-400 font-medium mb-2">
                      Contact Us
                    </div>
                    <div className="ml-auto ">
                      {Cookies.get("country") === "au"
                        ? "+61 (0)422967025"
                        : "+44 (0)203 948 1595"}
                    </div>
                    <div className="ml-auto ">clientservices@referment.com</div>
                  </div>
                </div>
              </div>

              <div className="pb-0 p-5 w-1/2 sm:w-4/12 md:w-3/12">
                <div className="text-xs uppercase medium text-gray-400 font-medium mb-6">
                  LINKS
                </div>

                <Link to={getUrl("")}>Home</Link>
                <Link
                  to={getUrl("about")}
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  About Us
                </Link>
                <Link
                  to={getUrl("faq")}
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  FAQ
                </Link>
                <Link
                  to={getUrl("clients")}
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  For clients
                </Link>
                <Link
                  to={"/blog"}
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  Blogs
                </Link>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://app.referment.com"
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  Referment platform
                </a>
              </div>

              <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
                <div className="text-xs medium uppercase text-gray-400 font-medium mb-6">
                  Legal
                </div>

                <Link
                  to="/terms"
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  Terms
                </Link>
                <Link
                  to={"/privacy"}
                  className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700"
                >
                  Privacy policy
                </Link>
              </div>
            </div>

            <div className="pb-16">
              <div
                className="flex pb-5 px-3 m-auto text-sm 
            flex-col-reverse md:flex-row max-w-6xl md:items-end md:justify-between"
              >
                <div className="mt-auto light text-center">
                  © Referment 2021 All Rights Reserved.
                </div>

                <div className="flex md:flex-col mt-8 flex justify-end flex-col">
                  {/* <div className=" mx-auto w-full">
                    <Img
                      className="mt-16 mb-8 md:mt-0 md:mb-0  w-full"
                      fluid={data.brentford?.childImageSharp?.fluid}
                    />
                  </div> */}
                  <div className="md:flex-auto md:flex-row-reverse mx-auto mt-2 flex-row flex">
                    <span className="mx-2">
                      <Email email={"clientservices@referment.com"} />
                    </span>
                    <Instagram url={"https://www.instagram.com/referment/"} />
                    <Twitter url={"https://twitter.com/referment"} />
                    <Linkedin
                      url={"https://www.linkedin.com/company/referment/"}
                    />
                    <span className="block lg:hidden">
                      <Phone />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </footer>
    </>
  )
}
