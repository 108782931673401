import React from "react"
import { Menu } from "@headlessui/react"
import { getUrl } from "../index"
import { Link } from "gatsby"

const CustomDropdown = () => {
  return (
    <Menu as="button" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-white rounded-md hover:border hover:bg-primary hover:bg-opacity-75	 ">
        About Us
        <svg
          fill={"white"}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="-8 -4 38 38"
          className="pt-1"
        >
          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
        </svg>
      </Menu.Button>
      <Menu.Items className="absolute left-0 w-56 pt-2 pb-2 mt-1 origin-top-right bg-light rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <Link
              to={getUrl("about")}
              className={` group flex rounded-md text-primary font-bold items-center w-full px-4 py-3 text-sm`}
            >
              About Us
            </Link>
          )}
        </Menu.Item>

        <Menu.Item>
          {({ active }) => (
            <Link
              to={getUrl("careers")}
              className={` group flex rounded-md text-primary font-bold items-center w-full px-4 py-3 text-sm`}
            >
              Careers at Referment
            </Link>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  )
}

export default CustomDropdown
