import React, { useState } from "react"
import Hamburger from "hamburger-react"
import { Link } from "gatsby"
import "./index.scss"
import { getUrl } from "../index"
import { Disclosure, Transition } from "@headlessui/react"

export default ({}) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div
        onClick={() => {
          setVisible(false)
        }}
        className={`menu ${visible ? "visibleMenu" : "hiddenMenu"}`}
      >
        <div
          onClick={e => e.stopPropagation()}
          className="sidebar overflow-scroll pb-12"
        >
          <Link to={"/blog"} id="home" className="text-secondary border-light">
            Blog
          </Link>
          <Link
            to={getUrl("faq")}
            id="home"
            className="text-secondary border-light"
          >
            FAQ
          </Link>
          <Disclosure>
            <Disclosure.Button className={"text-left text-secondary"}>
              <div>
                How It Works
                <svg
                  fill={"#5FF7D0"}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="-8 -4 38 38"
                  className="inline"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className={"pl-8"}>
                <Link
                  to={getUrl("howitworks")}
                  id="home"
                  className="text-secondary border-light"
                >
                  How it works
                </Link>
                <Link
                  to={getUrl("referralrewards")}
                  id="home"
                  className="text-secondary border-light"
                >
                  Referral Rewards
                </Link>
                <Link
                  to={getUrl("whatisareferral")}
                  id="home"
                  className="text-secondary border-light"
                >
                  What is a referral?
                </Link>
                <Link
                  to={getUrl("applyingyourself")}
                  id="home"
                  className="text-secondary border-light"
                >
                  Applying yourself
                </Link>
                <Link
                  to={getUrl("tailoryourreferment")}
                  id="home"
                  className="text-secondary border-light"
                >
                  Tailor your Referment
                </Link>
              </Disclosure.Panel>
            </Transition>
          </Disclosure>
          <Disclosure>
            <Disclosure.Button className={"text-left text-secondary"}>
              <div>
                About Us
                <svg
                  fill={"#5FF7D0"}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="-8 -4 38 38"
                  className="inline"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className={"pl-8"}>
                <Link
                  to={getUrl("about")}
                  id="home"
                  className="text-secondary border-light"
                >
                  About Us
                </Link>
                <Link
                  to={getUrl("careers")}
                  id="home"
                  className="text-secondary border-light"
                >
                  Careers at Referment
                </Link>
              </Disclosure.Panel>
            </Transition>
          </Disclosure>
          <div className={"px-8"}>
            <Link
              to={getUrl("clients")}
              style={{
                padding: "1rem",
                //   width: "fit-content",
                fontSize: "1.2rem",
              }}
              className="text-secondary text-xl mt-2  w-full text-center border-secondary border-solid rounded-sm border-2 p-0"
            >
              Hire with Referment
            </Link>
            <a
              href={"https://app.referment.com"}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                padding: "1rem",
                fontSize: "1.2rem",
              }}
              className="mt-8 bg-secondary text-primaryDark w-full text-center rounded-sm border-2 p-0 "
            >
              View Our Open Jobs
            </a>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <Hamburger
          style={{ zIndex: 9999 }}
          toggled={visible}
          toggle={setVisible}
        />
      </div>
    </>
  )
}
