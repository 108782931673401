import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Cookies from "js-cookie"

const Phone = () => {
  const data = useStaticQuery(graphql`
    query callicon {
      file(relativePath: { eq: "Referment Phone.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a
      href={
        Cookies.get("country") === "au"
          ? "tel:+61(0)422967025"
          : "tel:+44(0)3300523190"
      }
      className={"expand mx-2"}
    >
      <Img fixed={data?.file?.childImageSharp?.fixed} />
    </a>
  )
}

export default Phone
