import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const LinkedIn = ({ url }) => {
  const data = useStaticQuery(graphql`
    query linkedinicon {
      file(relativePath: { eq: "socialIcons/LinkedIn.png" }) {
        childImageSharp {
          fixed(width: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <a target="_blank" href={url} className={"mx-2 expand"}>
      <Img fixed={data.file.childImageSharp.fixed} />
    </a>
  )
}

LinkedIn.propTypes = {
  url: PropTypes.string,
}

export default LinkedIn
